import React, { useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { navigate } from "gatsby";

import LoadingError from "~/components/Loaders/LoadingError";
import SubscriptionsFilter from "~/components/Subscriptions/SubscriptionsFilter";
import api from "~/utils/api/api";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";
import Subscription from "~/utils/interfaces/Subscriptions";

type ActiveSubscriptionsProps = {
  country: "cl" | "mx";
};

const ActiveSubscriptions = (props: ActiveSubscriptionsProps) => {
  const [subscriptions, setSubscriptions] = useState([] as any[]); //ver type
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [totalRows, setTotalRows] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const req = await subscriptionsAPI.getAllSubscriptions(props.country);
        if (req.data.results.length > 0) {
          setSubscriptions(req.data.results);
          setTotalRows(req.data.count);
          setNextRequest(req.data.next?.replace("http", "https"));
          setPrevRequest(req.data.previous?.replace("http", "https"));
        } else {
          setError("No hay suscripciones activas");
        }
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleChangeSubscriptions = (subscriptions: Subscription[]): void => {
    setSubscriptions(subscriptions);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "get",
        baseURL: "",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setSubscriptions(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next?.replace("http", "https"));
      setPrevRequest(request.data.previous?.replace("http", "https"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const displayTableRows = (): Array<JSX.Element> => {
    return subscriptions.map((subscription: Subscription) => {
      return (
        <TableRow
          key={subscription.id}
          hover
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/subscriptions/${subscription.id}/`);
          }}
        >
          <TableCell>{`${subscription.user.first_name} ${subscription.user.last_name}`}</TableCell>
          <TableCell>{`${subscription.plan.category} - ${subscription.plan.name}`}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="py-4">
      <LoadingError loading={loading} />
      {error && <p>{error}</p>}
      {!loading && !error && (
        <div className="flex flex-col gap-y-10">
          <div className="self-center">
            <SubscriptionsFilter
              country={props.country}
              setSubscriptions={handleChangeSubscriptions}
            />
          </div>
          <div className="mx-12 mb-12">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="font-bold">Usuario</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">Suscripción</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{displayTableRows()}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalRows}
                      rowsPerPage={20}
                      rowsPerPageOptions={[20]}
                      page={currentPage}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveSubscriptions;
